<template>
  <div class="auth">
    <el-card class="auth__form">
      <el-form
        v-loading="loading"
        :model="dataForm.fields"
        :rules="dataForm.rules"
        :label-position="'top'"
        label-width="100px">
        <h2 class="auth__title">
          Вход в систему
        </h2>
        <el-form-item
          label="E-mail / Логин"
          prop="username">
          <el-input
            v-model="dataForm.fields.username"
            type="text"
            @keyup.enter="loginPromise" />
        </el-form-item>
        <el-form-item
          label="Пароль"
          prop="password">
          <el-input
            v-model="dataForm.fields.password"
            type="password"
            @keyup.enter="loginPromise" />
        </el-form-item>
        <el-form-item>
          <el-button
            class="pull-right"
            type="primary"
            @click="loginPromise">
            Войти
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Auth',
  data () {
    return {
      loading: false,
      dataForm: {
        fields: {
          username: '',
          password: ''
        },
        rules: {
          username: {
            required: true,
            message: 'Введите логин'
          },
          password: {
            required: true,
            message: 'Введите пароль'
          }
        }
      }
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    ...mapActions('oauth', ['userLogin']),

    loginPromise () {
      this.loading = true
      return this.userLogin(this.dataForm.fields).then(() => {
        this.$router.push({ name: 'ReportList' })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
  .auth {
    &__form {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 400px;
      height: 330px;
    }

    &__title {
      text-align: center;
      margin-top: 5px;
    }
  }
</style>
